import camelcase from 'camelcase-keys';
import { ACTION_TYPES } from './actions';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  appointmentForm: null,
  appointmentsTotalCount: null,
  appointmentsTotalPages: null,
  appointmentsTotalPages: null,
  appointmentsCurrentPage: null,
  updateAppointmentLoading: null,
  updateAppointmentError: null,
  updateAppointmentSuccess: null,
  duplicateAppointmentLoading: null,
  duplicateAppointmentError: null,
  duplicateAppointmentSuccess: null,
  getAppointmentLoading: null,
  getAppointmentError: null,
  totalPages: {},
  isSubmitting: false,
  wasSuccessful: false,
  loadedQueries: {},

  //
  appointments: {
    // id: {appointmentObj}
  },
  queries: {
    //query: { loading, loaded, error, data, totalPages }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    // SHOW
    case ACTION_TYPES.GET_APPOINTMENT:
      return {
        ...state,
        appointmentForm: null,
        getAppointmentLoading: true,
      };
    case ACTION_TYPES.GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        getAppointmentLoading: false,
        getAppointmentError: false,
        appointmentForm: {
          documents: action?.payload?.included?.find((e) => e.type === 'lead')
            ?.attributes?.documents,
          questions: action.form,
          answers: action.answers,
          id: action.payload?.data?.id,
        },
      };
    case ACTION_TYPES.GET_APPOINTMENT_FAIL:
      return {
        ...state,
        appointmentForm: null,
        getAppointmentError: true,
        getAppointmentLoading: false,
      };

    // UPDATE
    case ACTION_TYPES.UPDATE_APPOINTMENT:
      return {
        ...state,
        updateAppointmentLoading: true,
        updateAppointmentSuccess: false,
      };
    case ACTION_TYPES.UPDATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointmentForm: {
          ...state.appointmentForm,
          answers: { ...action?.answers },
        },
        updateAppointmentLoading: false,
        updateAppointmentError: false,
        updateAppointmentSuccess: true,
      };
    case ACTION_TYPES.UPDATE_APPOINTMENT_FAIL:
      return {
        ...state,
        updateAppointmentError: true,
        updateAppointmentLoading: false,
        updateAppointmentSuccess: false,
      };
    case ACTION_TYPES.DUPLICATE_APPOINTMENT:
      return {
        ...state,
        duplicateAppointmentLoading: true,
        duplicateAppointmentSuccess: false,
      };
    case ACTION_TYPES.DUPLICATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        duplicateAppointmentLoading: false,
        duplicateAppointmentError: false,
        duplicateAppointmentSuccess: true,
      };
    case ACTION_TYPES.DUPLICATE_APPOINTMENT_FAIL:
      return {
        ...state,
        duplicateAppointmentError: true,
        duplicateAppointmentLoading: false,
        duplicateAppointmentSuccess: false,
      };

    // CREATE NEW
    case ACTION_TYPES.CREATE_NEW_APPOINTMENT:
      return {
        ...state,
        isSubmitting: true,
      };
    case ACTION_TYPES.CREATE_NEW_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointments: [],
        loadedQueries: {},
        isSubmitting: false,
        wasSuccessful: true,
      };
    case ACTION_TYPES.CREATE_NEW_APPOINTMENT_FAIL:
      return {
        ...state,
        error: action.error,
        isSubmitting: false,
        wasSuccessful: false,
      };

    // REMOVE APPOINT PROVIDER
    case ACTION_TYPES.REMOVE_APPOINTMENT_PROVIDER:
      return {
        ...state,
        getAppointmentLoading: true,
      };
    case ACTION_TYPES.REMOVE_APPOINTMENT_PROVIDER_SUCCESS:
      return {
        ...state,
        appointments: state.appointments,
        loadedQueries: {},
        getAppointmentLoading: false,
        wasSuccessful: true,
      };
    case ACTION_TYPES.REMOVE_APPOINTMENT_PROVIDER_FAIL:
      return {
        ...state,
        getAppointmentError: action.error,
        getAppointmentLoading: false,
        wasSuccessful: false,
      };

    case ACTION_TYPES.GET_APPOINTMENTS: {
      return {
        ...state,
        queries: {
          ...state.queries,
          [action.query]: {
            loading: true,
            loaded: false,
            error: false,
            totalPages: null,
            data: [],
          },
        },
      };
    }
    case ACTION_TYPES.GET_APPOINTMENTS_SUCCESS: {
      const data = [...camelcase(action.payload.data, { deep: true })];
      const appointmentIds = data?.map((e) => e.id);
      const updatedAppsObj = { ...state.appointments };
      appointmentIds.forEach((id) => (updatedAppsObj[id] = data.find((e) => e.id === id)));
      const totalPages = parseInt(action.meta?.['Total-Pages']);

      return {
        ...state,
        appointments: updatedAppsObj,
        queries: {
          ...state.queries,
          [action.query]: {
            loading: false,
            loaded: true,
            error: false,
            totalPages: totalPages,
            data: appointmentIds,
          },
        },
      };
    }
    case ACTION_TYPES.GET_APPOINTMENTS_FAIL: {
      return {
        ...state,
        queries: {
          ...state.queries,
          [action.query]: {
            loading: false,
            loaded: false,
            error: action.error,
            data: null,
          },
        },
      };
    }

    default:
      return state;
  }
};
