import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/polyglot';
import { useLocation } from 'react-router-dom';
import useFilters from 'hooks/useFilters';

import { Icon } from 'ui-55';
import Select from 'Components/Select';
import Datepick from 'Components/Datepick';

import StyledFilterButton, {
  CloseContainer,
  FilterTitle,
  OpenDropdown,
  Dropdown,
  DateDropdown,
  BodyDetail,
  overrideSelect
} from './style';

const getIconName = filterType => {
  switch (filterType) {
    case 'admin_id':
      return 'User';
    case 'start_date':
      return 'calendar';
    case 'delivery_start_date':
      return 'calendar';
    case 'service_ids':
      return 'tool-1';
    case 'status':
      return 'activity';
    case 'city_ids':
      return 'Language';
    case 'newsletter':
      return 'mail';
    case 'recurring':
      return 'Clock';
    case 'tag':
      return 'activity';
    default:
      return 'User';
  }
};

const FilterButton = (props) => {
  const {
    filterType,
    filterOptions,
    selectedLabel,
    onChange,
    onClose,
    isMulti
  } = props;

  const t = useTranslate('filters');
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const { getFiltersFromQuery, active } = useFilters(location);
  const [selectValue, setSelectValue] = useState(null);

  const handleOpen = e => {
    setShowDropdown(!showDropdown);
  };

  const handleClose = e => {
    onClose(filterType);
  };

  const handleSelect = e => {
    setSelectValue(e);
    onChange(e, filterType);
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const updateSelectHandler = () => {
      const prevSelectValue = [];
      for (const filterValue of active[filterType]) {
        prevSelectValue.push(filterOptions.find((option) => option.value === +filterValue))
      }
      setSelectValue(prevSelectValue);
    }

    if (!selectValue && active[filterType] && filterOptions) {
      updateSelectHandler(active[filterType])
    }
  }, [active]);

  const transformLabel = selectedLabel => {
    const filters = getFiltersFromQuery(location?.search)
    if (filterType === 'start_date' || filterType === 'delivery_start_date') {
      if (selectedLabel.includes('todos')) return 'Todos';
      return selectedLabel.replaceAll('-', '/');
    } else if (filterType === 'latitude') {
      return filters.latitude ? filterOptions[0]?.label : 'Todos'
    } else if (filterType === 'availability') {
      return filters.availability ? filterOptions[0]?.label : 'Todos'
    } else {
      return selectedLabel;
    }
  };

  return (
    <StyledFilterButton>
      <FilterTitle filterLabel={filterType}>
        <Icon name={getIconName(filterType)} />
        <span>{t(filterType)}:</span>
      </FilterTitle>
      <OpenDropdown onClick={handleOpen}>
        {selectedLabel ? (
          <>{filterType !== 'start_date'
            ? transformLabel(selectedLabel)
            : selectedLabel
          }</>
        ) : (
          <BodyDetail>{t('all')}</BodyDetail>
        )}
        <Icon name='chevron-down' />
      </OpenDropdown>

      {onClose && (
        <CloseContainer onClick={() => handleClose()}>
          <Icon name='Close' />
        </CloseContainer>
      )}

      {((filterType === 'start_date') || (filterType === 'delivery_start_date')) && (
        <>
          {showDropdown && (
            <DateDropdown>
              <Datepick
                toggleExtension={setShowDropdown}
                type={filterType}
              />
            </DateDropdown>
          )}
        </>
      )
      }

      {(filterType !== 'start_date' && filterType !== 'delivery_start_date') && (
        <>
          {showDropdown && (
            <Dropdown length={filterOptions?.length} isMulti={isMulti}>
              <Select
                isMulti={isMulti}
                options={filterOptions}
                onChange={handleSelect}
                overrideStyles={overrideSelect(isMulti)}
                isMenuOpen={true}
                value={selectValue}
              />
            </Dropdown>
          )}
        </>
      )}
    </StyledFilterButton>
  );
};

FilterButton.propTypes = {
  filterType: PropTypes.oneOf([
    'admin_id',
    'tag',
    'newsletter',
    'recurring',
    'start_date',
    'delivery_start_date',
    'service_ids',
    'status',
    'city_ids'
  ]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  onClose: PropTypes.func
  // leftIcon: PropTypes.string,
  // rightIcon: PropTypes.string
};

FilterButton.defaultProps = {
  // leftIcon: "kanban",
  // rightIcon: "List"
};

export default FilterButton;
