import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '../../../features/polyglot';

import { Icon, Col, Row } from 'ui-55';

import {
  StyledTableRow,
  ServiceTotalRow,
  PaymentInput,
  DescriptionInput,
  InputContainer,
  SmallBodyDetail,
  UnderlineCell
} from './styles';

const TableRow = ({
  lineType,
  lineItem,
  totals,
  appointment,
  onChange,
  isDisabled,
  tableType
}) => {
  const t = useTranslate('payment');

  const splitDescription = lineItem.description?.split(' - ');

  const [adjDescription, setAdjDescription] = useState(lineItem.description);
  const [adjAmount, setAdjAmount] = useState(lineItem.unitAmount);
  const handleAdjustment = (type, v) => {
    if (type === 'description') {
      setAdjDescription(v.target.value)
    } else {
      setAdjAmount(v.target.value * 100)
    }
  }

  useEffect(() => {
    if (lineType === 'adjustment' && onChange) {
      onChange({
        description: adjDescription,
        unitAmount: adjAmount
      })
    }
  }, [adjAmount, adjDescription])

  return (
    <>
      {lineItem &&
        <>
          {lineType === 'service' &&
            <StyledTableRow>
              <Col size={6}>
                {splitDescription &&
                  splitDescription[0]}
              </Col>

              <Col size={3}>
                {lineItem.itemType === 'service_pack' ?
                  (totals[lineType] / 100).toFixed(2)
                  :
                  appointment?.attributes?.hourlyRate.toFixed(2)
                }€
              </Col>

              <Col size={2}>
              {splitDescription &&
                  splitDescription[1]}
              </Col>

              <Col size={1}>
                {(totals[lineType] / 100).toFixed(2)}€
              </Col>

            </StyledTableRow>
          }

          {lineType === 'urgent' &&
            <StyledTableRow>
              <Col size={6}>
                {splitDescription &&
                  splitDescription[0]}
              </Col>

              <Col size={3}>
                {tableType === 'Client' &&
                  <>
                    {((lineItem.unitAmount / appointment?.attributes?.totalHours) / 100).toFixed(2)}€
                  </>
                }

                {tableType === 'Provider' &&
                  <>
                    {(appointment?.attributes?.providerUrgentPrice / 100).toFixed(2)}€
                  </>
                }
              </Col>

              <Col size={2}>
              {splitDescription &&
                  splitDescription[1]}
              </Col>

              <Col size={1}>
                {tableType === 'Client' &&
                  <>
                    {(lineItem.unitAmount / 100).toFixed(2)}€
                  </>
                }

                {tableType === 'Provider' &&
                  <>
                    {(appointment?.attributes?.providerUrgentPrice * appointment?.attributes?.totalHours / 100).toFixed(2)}€
                  </>
                }
              </Col>

            </StyledTableRow>
          }

          {lineType === 'discount' &&
            <>
            <Row>
              <Col size={6} />

              <UnderlineCell size={3}>{t('discount')}</UnderlineCell>
              <InputContainer size={2}>
                <SmallBodyDetail>
                  {isDisabled
                  ?
                    <>
                    {((lineItem.amountOff / totals.service) * 100).toFixed()}
                    </>
                  :
                    <PaymentInput
                      defaultValue={((lineItem.amountOff / totals.service) * 100).toFixed()}
                      onChange={(v) => onChange(v)}
                      type='number'
                      placeholder={((lineItem.amountOff / totals.service) * 100).toFixed()}
                      step="1"
                      min="0"
                    />
                  }
                  {` %`}
                </SmallBodyDetail>
              </InputContainer>
              <UnderlineCell size={1}>-{(lineItem.amountOff / 100).toFixed(2)}€</UnderlineCell>
            </Row>

            <ServiceTotalRow>
              <Col size={6} />

              <Col size={3}>{t('total')}</Col>
              <Col size={2}></Col>
              <Col size={1}>{(totals.discount / 100).toFixed(2)}€</Col>
            </ServiceTotalRow>
            </>
          }

          {lineType === 'km' &&
            <Row>
              <UnderlineCell size={6}>{t('travel')}</UnderlineCell>

              <UnderlineCell size={3}>{(appointment?.attributes?.kmPrice / 100).toFixed(2)}€</UnderlineCell>
              <UnderlineCell size={2}>
                {splitDescription &&
                    splitDescription[1]}
              </UnderlineCell>
              <UnderlineCell size={1}>{(lineItem.unitAmount / 100).toFixed(2)}€</UnderlineCell>
            </Row>
          }

          {lineType === 'adjustment' &&
            <StyledTableRow>
              {isDisabled
              ?
                <>
                  <Col size={6}>
                    <SmallBodyDetail>
                      {lineItem.description}
                    </SmallBodyDetail>
                  </Col>

                  <Col size={3}>
                    <SmallBodyDetail>
                      {(lineItem.unitAmount / 100).toFixed(2)} {` €`}
                    </SmallBodyDetail>
                  </Col>
                </>
              :
                <>
                  <Col size={6}>
                    <SmallBodyDetail>
                      <DescriptionInput
                        defaultValue={adjDescription}
                        onChange={(v) => handleAdjustment('description',v)}
                        type='text'
                        placeholder={lineItem.description}
                      />
                    </SmallBodyDetail>
                  </Col>

                  <Col size={3}>
                    <SmallBodyDetail>
                      <PaymentInput
                        defaultValue={(adjAmount / 100).toFixed(2)}
                        onChange={(v) => handleAdjustment('amount',v)}
                        type='number'
                        placeholder={(adjAmount / 100).toFixed(2)}
                        min="0"
                        step="0.01"
                      />
                    </SmallBodyDetail>
                  </Col>
                </>
              }

              <Col size={2}>
                {!isDisabled &&
                  <div
                    role='button'
                    tabIndex={0}
                    onClick={() => onChange('removeLine')}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onChange('removeLine');
                      }
                    }}
                  >
                    <Icon
                      name='Close'
                    />
                  </div>
                }
              </Col>

              <Col size={1}>
                {(totals[lineType] / 100).toFixed(2)}€
              </Col>

          </StyledTableRow>
          }
        </>
      }
    </>
  );
};

export default TableRow;

TableRow.propTypes = {
  lineType: PropTypes.oneOf(['service', 'discount', 'adjustment', 'urgency', 'km']),
  lineItem: PropTypes.object,
  totals: PropTypes.shape({
    service: PropTypes.number,
    discount: PropTypes.number,
    adjustment: PropTypes.number,
    urgency: PropTypes.number,
    km: PropTypes.number
  }),
  appointment: PropTypes.object,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  tableType: PropTypes.oneOf(['Client', 'Provider'])
};
