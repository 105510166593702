import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createNewClient } from 'Redux/clients/actions';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslate } from '../../features/polyglot';

import { Loader, Pagination, BackofficeContainer } from 'ui-55';
import { toast } from 'react-toastify';

import useFilters from 'hooks/useFilters';
import useClients from 'hooks/clients/useClients';

import Filters from 'components/Filters';
import ClientTable from 'Components/ClientTable';
import NewClient from './NewClient';

const AVAILABLE_FILTERS = ['cities', 'newsletter'];
const MULTI_FILTERS = ['city_ids'];

const Clients = () => {
  const t = useTranslate('clients');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [openNewClient, setOpenNewClient] = useState(false);
  const { active, queryString, page, getQueryStringFromFilters } = useFilters(
    location
  );
  const { loading, clients, totalPages } = useClients({
    query: queryString,
    shouldFetch: true,
  });

  const handleNavigation = (pageNumber) => {
    const string = getQueryStringFromFilters(active);
    history.push(
      `${location?.pathname}${string}${string ? '&' : '?'}page=${parseInt(
        pageNumber
      )}`
    );
  };

  const handleOpenNewClient = () => {
    setOpenNewClient(true);
  };

  const handleClientDetails = (client) => {
    history.push(`/dashboard/client-detail?client_id=${client.id}&page=1`);
  };

  const handleNewClientSubmit = (values) => {
    const payload = {
      address: values?.address,
      company_status: values?.company_status,
      country: values?.country,
      district: values?._district,
      email: values?.email,
      full_name: values?.firstName.concat(' ', values?.lastName),
      location: values?.location,
      nif: values?.nif,
      phone_number: values?.telephone,
      postal_code: values?.postalCode,
    };
    setOpenNewClient(!openNewClient);
    createNewClient(dispatch, payload, {
      success: (id) => {
        toast.success('Cliente adicionado com sucesso !');
        history.push(`/dashboard/client-detail?client_id=${id}`);
      },
      error: (e) => toast.error(t(e + '-error') || 'Erro a adicionar cliente'),
    });
  };

  return (
    <BackofficeContainer>
      <Filters
        availableFilters={AVAILABLE_FILTERS}
        multiFilters={MULTI_FILTERS}
        showLayout={false}
        hasAddButton={true}
        addButtonAction={handleOpenNewClient}
        hasCSVLink="true"
      />
      <NewClient
        isOpen={openNewClient}
        action={{
          submit: (values) => handleNewClientSubmit(values),
          cancel: () => setOpenNewClient(false),
        }}
      />
      {(loading && <Loader />) || (
        <>
          <ClientTable items={clients} action={handleClientDetails} />

          <Pagination
            translate={t}
            totalPages={totalPages}
            currentPage={page}
            action={handleNavigation}
          />
        </>
      )}
    </BackofficeContainer>
  );
};

export default Clients;
