import React, { useEffect } from "react";
import {
  HeadingDetail,
  StyledTagBadge,
  Card
} from "./style";
import useProvider from 'Hooks/providers/useProvider';
import { Body } from 'Components/Text';
import { Icon } from 'ui-55';
import Select from 'Components/Select';
import useTagsListing from 'Hooks/tags/useTagsListing';
import { useTranslate } from '../../features/polyglot';

const CardTagProvider = (props) => {
  const t = useTranslate('tag');

  const { tags } = useTagsListing({
    shouldFetch: true,
  });
  const tagOptions = tags.map(tag => {
    return {
      label: tag.attributes?.name,
      value: tag.id
    };
  });
  const {
    updateProvider,
    getProvider
  } = useProvider();

  useEffect(() => {
    getProvider(props?.provider?.id);
  }, []);

  const provider = props?.provider;
  const handleSelectChange = (newTag) => {
    const anotherTags = provider.attributes.tags.map(tag => ({ id: tag.id }));
    const newTags = [...anotherTags, { id: newTag.value }];
    const payload = {
      tags: newTags
    };
    updateProvider(provider.id, payload);
  }
  const handleRemoveTag = (tagToRemove) => {
    const newTags = provider.attributes.tags.filter(tag => tag.id !== tagToRemove.id).map(tag => ({ id: tag.id }));
    const payload = {
      tags: newTags
    };
    updateProvider(provider.id, payload);
  }

  if (provider === undefined || provider?.attributes?.tags === undefined || provider?.attributes?.tags === null) {
    return (
      <></>
    );
  }

  return (
    <>
      <Card>
        <HeadingDetail size={6}>{t('tags')}</HeadingDetail>
        <Select
          options={tagOptions}
          onChange={v => handleSelectChange(v)}
          selectType='tag'
          value={null}
        /><br />
        {provider?.attributes?.tags?.length === 0 && (<Body alt='true'>Não tem Tag Selecionada</Body>)}
        {Object.keys(provider?.attributes?.tags).map((key) => {
          return (
            <React.Fragment key={key}>
              <StyledTagBadge>
                <div
                  role='button'
                  tabIndex={0}
                  onClick={() =>
                    handleRemoveTag(provider?.attributes?.tags[key])
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleRemoveTag(provider?.attributes?.tags[key]);
                    }
                  }}
                >
                  {provider?.attributes?.tags[key]?.name}
                  <Icon name='Close' />
                </div>
              </StyledTagBadge>
            </React.Fragment>
          );
        })}
      </Card>
    </>
  );
}

export default CardTagProvider;
